@import "../../../assets/_var.scss";
.header-style {
  &.navbar {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    justify-content: space-between;
    padding: 2.3rem 6.5rem;
    transition: .3s all ease-in-out;
    .navbar-brand {
      padding: 0;
      img {
        max-width: 20.8rem;
      }
      @media (max-width: 991px) {
        position: relative;
        z-index: 112;
        img {
          max-width: 10rem;
        }
      }
    }
    .btn-style {
      margin-left: 3rem;
    }
    @media (max-width:1199px) {
      padding-left: 15px;
      padding-right: 15px;
      .btn-style {
        margin-left: 1rem;
      }
    }
    @media (max-width:359px) {
      .btn-style {
        font-size: 1.2rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
    &.scrolled {
      background-color: rgba(0, 0, 0, .7);
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      box-shadow: 0 10px 14px rgba(251, 210, 84, .1);
    }
  }
}