@import "../../../assets/_var.scss";

.admin-layout {
  padding-left: 25rem;
  min-height: 100vh;
  background: $baseBg-3;
  transition: .23s padding-left ease-in-out;

  .admin-content-handle {
    padding: 3rem;
    @media (max-width:1199px) {
      padding: 3rem 1.5rem;
    }
  }
  &.expanded {
    padding-left: 0;
  }
  @media (max-width:1199px) {
    padding-left: 0;
  }
}
