@import '../../../../assets/_var.scss';
.page_nation {
    margin-top: 4rem;
    display: flex;
    align-items: center;
     
    li {
        a, span {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 1.4rem;
            font-weight: 600;
            color: $white;
            height: 4rem;
            width: 4rem;
            border: 1px solid $baseBg-4;
            transition: .3s all ease-in-out;
            text-decoration: none;
            border-radius: 0 !important;
            background-color: transparent;
            border: 1px solid $yellowclr !important;
            
        }
        &.active, &:hover {
            span, a {
                background-color: $yellowclr !important;
            color: $baseBg-4 !important;
            }
        }
    }
}