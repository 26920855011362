// @import "~bootstrap/scss/bootstrap";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./assets/_var.scss";
body {
  margin: 0;
  font-family: $baseFont, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $baseBg;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img,
svg {
  max-width: 100%;
}
.container {
  max-width: 1530px;
  padding: 0 15px;
  // @media (max-width: 1679px) {
  //   max-width: 1250px;
  // }
}
.row {
  margin: 0 -15px;
  >div {
    padding: 0 15px;
  }
}
html {
  font-size: 60.6666%;
}
ul {
  padding-left: 0;
  list-style: none;
}
iframe {
  display: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}
p {
  font-size: 1.6rem;
}
h2 {
  font-size: 2.8rem;
}
.my-30 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.form-control {
  height: 4.8rem;
  border-radius: 3rem;
  font-size: 1.3rem;
  color: $white;
  font-weight: 600;
  padding: 1rem 2rem;
  width: 100%;
  border: 0;
  background-color: rgba(255, 255, 255, 0.3);
  &:focus {
    box-shadow: unset;
    color: $white;
    background-color: rgba(255, 255, 255, 0.3);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.commn-bg {
  background-color: $baseBg-4;
  border-radius: 1rem;
}
.input__wrap {
  position: relative;
  display: flex;
  align-items: center;
  .form-control {
    // margin-top: 1rem;
    padding-right: 12rem;
    &::placeholder {
      color: $white;
    }
    &::-webkit-input-placeholder {
      color: $white;
    }
    @media (max-width:991px) {
      padding-right: 9.5rem;
    }
  }
  .btn-style {
    position: absolute;
    right: 1rem;
    font-size: 1.3rem;
    min-width: 10rem;
    padding: 0.7rem 1.5rem;
    @media (max-width:991px) {
      min-width: inherit;
    }
  }
}
.form-label {
  color: $white;
  font-size: 1.6rem;
  display: block;
  font-weight: 600;
  margin-bottom: 1.5rem;
  @media (max-width: 991px) {
    font-size: 1.4rem;
  }
}
.react-datetime-picker__inputGroup__input option {
  background: #231731;
}
.modal {
  &-content {
    border-radius: 1.5rem;
   
  }
  &-backdrop {
    opacity: 0.8;
  }
  &-header {
    // border-color: rgba(224, 30, 215, .5);
    border: 0;
    padding: 2rem;
    background-color: $purple500;
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    box-shadow: 0 1.5rem 1.4rem rgba(251, 210, 84, 0.22);
    * {
      color: $white;
    }
    .modal-title {
      h3 {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
    .btn-close {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: $yellowclr;
      opacity: 1;
    }
  }
  &-content {
    background-color: $purplelight;
  }
// &:not(.TicketModal) {
//   backdrop-filter: blur(5px);
// }
}
.customCheckbox {
  .form-check-input {
    width: 1.8em;
    margin-right: 1rem;
height: 1.8em;
&:checked {
  background-color: $yellowclr;
  box-shadow: none;
}
  }
  .form-check-label {
    font-size: 1.2rem;
    font-weight: 600;
    color: $white;
    position: relative;
top: 3px;
cursor: pointer;
  }
}
.errorMsg 
{
  color: $white;
  font-size: 1.2rem;
  margin: 1rem 0 0 0;
}