@keyframes blink {
	0% {opacity: 0}
    100% {opacity: 1;}
}
@import '../../../../../assets/_var.scss';

.AboutSec {
    padding-top: 5rem;
    padding-bottom: 7rem;
    // min-height: 100vh;
    // background-image: url(https://d1ygcv609zmhy5.cloudfront.net/about-bg.png);
    // background-position: top center;
    // background-size: cover;
    // background-repeat: no-repeat;
    .eventSec {
        padding-bottom: 13rem;

        h2 {
            color: $yellowclr;
            font-size: 3rem;
            font-weight: 700;
            margin-bottom: 3rem;
        }

        p {
            font-size: 1.4rem;
            color: $white;
            margin-bottom: 1.5rem;
            line-height: 2;
            padding-right: 6rem;
        }

        h3 {
            margin-bottom: 6rem;
            color: $yellowClr2;
            font-weight: 600;
            line-height: 2;
            font-size: 2.1rem;
            padding-right: 6rem;
            &.top {
                position: relative;
                margin-bottom: 4rem;
                padding: 2rem 4rem;
                width: calc(100% - 6rem);
                &:before {
                    transition: 2s all ease-in-out;
                    animation: blink 2s infinite;
                    content: "";
                    box-shadow: inset 0px 0px 16px #00759C29, 0px 0px 20px #03C0FF;
                    border: 5px solid #FFFFFF;
                    border-radius: 24px;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
                @media (max-width:991px) {
                    width: 100%;

                }
                @media (max-width:575px) {
                    padding: 2rem;
                }
            }
        }
    }

    @media (max-width:1679px) {
        padding-top: 0;

        // padding-bottom: 10rem;
        .eventSec {
            h2 {
                font-size: 2.8rem;
            }

            padding-bottom: 8rem;

            h3 {
                font-size: 1.8rem;
            }
        }
    }

    @media (max-width:1439px) {

        // padding-bottom: 10rem;
        .eventSec {
            h2 {
                font-size: 2.6rem;
            }

            padding-bottom: 7rem;

            h3 {
                font-size: 1.6rem;
                margin-bottom: 3rem;
            }
        }
    }

    @media (max-width:991px) {
        .eventSec {
            text-align: center;
            padding-bottom: 4rem;

            p,
            h3 {
                padding-right: 0;
            }
        }
    }
}

