@import '../../../assets/_var.scss';

.TicketHandling {
    &Table {
        table {
            padding: 2rem;

            thead {
                tr {
                    th {
                        &:first-child {
                            border-top-left-radius: 1rem;
                        }

                        &:last-child {
                            border-top-right-radius: 1rem;
                        }
                    }
                }
            }

            tbody {
                tr {
                    &:last-child {
                        td {
                            border-bottom: 0;

                            &:first-child {
                                border-bottom-left-radius: 1rem;
                            }

                            &:last-child {
                                border-bottom-right-radius: 1rem;
                            }
                        }
                    }


                }
            }

            .act {
                text-decoration: none;
                font-size: 2rem;
                color: $yellowclr;
                font-weight: bold;
            }
        }

        th,
        td {
            background-color: $baseBg-4  !important;
            color: $white  !important;
            padding: 2rem !important;
            border-color: $borderClr2;
            font-size: 1.4rem;
            text-align: center;
        }

        &.ModalTable {
            tr {

                td,
                th {
                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }

    &Fltr {
        .dashboard-card {
            height: 100%;
            &.fltr {
                flex-direction: column;
                > * {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }
        .customSelect {
            &Inner__control {
                height: 4.3rem;
                background-color: transparent;
                border-color: $white  !important;

                * {
                    color: $white;
                }

                &:focus,
                &:hover {
                    box-shadow: none;
                    border-color: $white  !important;
                    outline: none !important;
                }
            }

            &Inner__single-value {
                font-size: 1.2rem;
                font-weight: 600;
            }

            &Inner__menu {
                background-color: $baseBg-4;

                .customSelectInner__option {
                    font-weight: 600;
                    color: $white;
                }

                .customSelectInner__option--is-focused,
                .customSelectInner__option--is-selected {
                    background-color: $yellowclr;
                    color: $baseBg-4;
                }
            }
        }
    }
}