@import '../../../../../assets/_var.scss';

.AboutFaq {
    @media (max-width:1679px) {
        padding: 0 5rem;
    }

    @media (max-width:1199px) {
        padding: 0;
    }

    .accordion {
        background-color: transparent;
        border: 0;

        &-item {
            margin-top: 3rem;
            border-radius: 2rem !important;
            background-color: rgba(255, 255, 255, .2);
            position: relative;
            border: 0;
            border-radius: 2rem;
            padding: 4.5rem 6rem;
            opacity: 1;
            backdrop-filter: blur(16px);
            -webkit-backdrop-filter: blur(16px);

            // &::before {
            //     content: "";
            //     height: 100%;
            //     width: 100%;
            //     position: absolute;
            //     left: 0;
            //     top: 0;
            //     z-index: -1;
            //     border-radius: 2rem;
            //     background-color: rgba(0,0,0,1);
            // }
            @media (max-width:1679px) {
                padding: 3.5rem 4rem;
            }

            @media (max-width:1439px) {
                padding: 3rem;

            }
        }

        &-button {
            border-radius: 0;
            padding: 0;
            padding-right: 4.7rem;
            font-size: 3rem;
            font-weight: 700;
            color: $yellowclr;
            background-color: transparent;
            position: relative;

            &:focus {
                outline: none;
                border: 0;
                box-shadow: none;
            }

            &:after {
                background-image: url(../../../../../assets/Images/plus.png);
                height: 40px;
                width: 40px;
                background-size: cover;
                margin-left: 1rem;
                position: absolute;
                right: 0;
            }

            &:not(.collapsed) {
                background-color: transparent;
                border: 0;
                box-shadow: none;
                color: $yellowclr;

                &:after {
                    background-image: url(../../../../../assets/Images/minus.png);
                }
            }

            @media (max-width:1679px) {
                font-size: 2rem;


                &:after {
                    height: 30px;
                    width: 30px;
                }
            }

            @media (max-width:1439px) {
                font-size: 1.8rem;


                &:after {
                    height: 25px;
                    width: 25px;
                }
            }

            @media (max-width:1199px) {
                font-size: 1.6rem;

                &:after {
                    height: 20px;
                    width: 20px;
                }
            }

            &:before {
                content: "";
                height: calc(100% + 9rem);
                width: calc(100% + 12rem);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 2rem;

                @media (max-width:1679px) {
                    height: calc(100% + 6.5rem);
                    width: calc(100% + 8rem);
                }
                @media (max-width:1439px) {
                    height: calc(100% + 6rem);
width: calc(100% + 6rem);
                }
            }
        }

        &-body {
            border-top: 1px solid rgba(255, 255, 255, .1);
            padding: 0;
            padding-top: 1rem;
            margin-top: 3rem;

            p {
                padding-top: 1.5rem;
                font-size: 1.4rem;
                color: $white;
                line-height: 2;

                span,
                strong {
                    color: $yellowclr;
                }
            }
        }
    }
}