@import "../../../assets/_var.scss";

.admin-header {
  padding: 2.5rem 3rem;
  border-bottom: 0.1rem solid $borderClr2;
  color: $white;
  h2 {
    font-size: 2.4rem;
    font-weight: 600;
  }

  .tglBtn {
    @media (min-width:1200px) {
      display: none;
    }

    background-color: transparent;
    border: 0;
    width: 3rem;
    margin: 0 0 0 1rem;
    padding: 0;

    @media (max-width:991px) {
      width: 2rem;
    }
  }

  @media (max-width:1679px) {
    h2 {
      font-size: 2.2rem;
    }
  }

  @media (max-width:1199px) {
    padding: 2.5rem 1.5rem;
  }

  @media (max-width:991px) {
    h2 {
      font-size: 1.8rem;
    }

    .btn-style {
      font-size: 1.4rem;
      padding: .7rem 1.2rem;
    }
  }

  @media (max-width:767px) {
    h2 {
      font-size: 1.4rem;
    }

    .btn-style {
      font-size: 1.2rem;
    }
  }

  @media (max-width:359px) {
    h2 {
      font-size: 1.2rem;
    }
  }
}