@import "../../../assets/_var.scss";
.TicketModal {
  .modal-header {
    @media (min-width:1280px) {
      display: none;
    }
    border: 0;
    padding: 0;
    position: absolute;
    right: 2rem;
    top: 2rem;
    background-color: rgba(255, 255, 255, .5);
    height: 4rem;
    width: 4rem;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    button {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .modal-dialog {
    padding: 2rem 1rem;
  }
  .modal-content {
    .modal-body {
      padding: 0;
    }
    border-radius: 2.1rem;
    max-width: 520px;
    overflow: hidden;
    height: 640px;
    background-image: url(../../../assets/Images/ticket-bg.jpg);
    // background-image: url(https://d1ygcv609zmhy5.cloudfront.net/token-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 2rem;
    @media (max-width:1539px) {
      height: 560px;
    }
    @media (max-width:991px) {
      padding: 2rem 1rem;
      height: 600px;
    }
    .TicketHandle {
      margin: -3rem -2rem 2.4rem -2rem;
      background-image: url(https://d1ygcv609zmhy5.cloudfront.net/tickethandle-bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border-radius: 2rem;
      overflow: hidden;
      padding: 3rem 9rem 3.2rem 2.5rem;
      position: relative;
      &:after {
        content: "";
        height: calc(100% - 20px);
        width: 1px;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        border-right: 1px dashed rgba(255, 255, 255, .6);
        right: 5.7rem;
      }
      h2 {
        color: $yellowclr;
        display: block;
        font-size: 1.6rem;
        border-bottom: 1px solid rgba(255, 255, 255, .3);
        padding-bottom: 1.2rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      ul {
        margin: 0;
        li {
          display: flex;
          justify-content: space-between;
          color: $white;
          font-weight: 600;
          font-size: 1.1rem;
          padding-top: 1rem;
          strong {
            &:first-child {
              padding-right: .5rem;
            }
            &:last-child {
              padding-left: .5rem;
            }
          }
        }
      }
      img {
        width: 3.33rem;
        position: absolute;
        right: 1.5rem;
        transform: translateY(-50%);
        top: 50%;
      }
      @media (max-width:991px) {
        margin: -2rem -1rem 2rem -1rem;
      }
    }
    .timezo,
    .ticket-no {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid rgba(255, 255, 255, .3);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        font-size: 1.1rem;
        color: $white;
        font-weight: 600;
        margin-bottom: .5rem;
      }
      .right {
        button {
          border: 1px solid $white;
          padding: .5rem;
          border-radius: .5rem;
          color: $black;
          display: inline-flex;
          font-size: 1.1rem;
          min-width: 5.5rem;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          margin: 0 0 .5rem 1.8rem;
          font-weight: 600;
          height: 2.3rem;
          color: $white;
          &.active {
            background-color: $white;
            color: $black;
          }
          @media (max-width:991px) {
            margin: 0 0 .5rem 1rem;
          }
        }
        &.topay {
          font-weight: 600;
          color: $white;
        }
      }
    }
    .ticket-no {
      border-bottom: 0;
      margin-bottom: 0;
      input {
        height: 2.3rem;
        width: 5rem;
        border-radius: .5rem;
        border: 1px solid $white;
        padding: .5rem;
        font-size: 1.1rem;
        margin: 0 0 .5rem 1.8rem;
        background-color: transparent;
        font-weight: 600;
        color: $white;
        vertical-align: middle;
        text-align: center;
        &:focus {
          outline: none;
          text-align: center;
        }
        @media (max-width:991px) {
          width: 4rem;
          margin: 0 0 .5rem 1rem;
        }
      }
      .right {
        button {
          font-size: 2rem;
          background-color: $white;
          font-weight: normal;
          color: $black;
          vertical-align: middle;
          @media (max-width:991px) {
            margin: 0 0 .5rem 1rem;
            min-width: 4rem;
            font-size: 1.8rem;
          }
        }
      }
    }
    .ticket-action {
      button {
        margin-top: 1rem;
        width: 100%;
        justify-content: space-between;
        font-size: 1.4rem;
      }
    }
    .ticketConfirmation {
      h2 {
        text-align: center;
        color: $yellowclr;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2rem;
      }
      p,
      label {
        font-size: 1.4rem;
        font-weight: 600;
        text-align: center;
        color: $white;
        @media (max-width:991px) {
          font-size: 1.2rem;
        }
      }
      label {
        font-size: 1.2rem;
        display: block;
        text-align: left;
        margin-bottom: .5rem;
        margin-top: 3rem;
      }
      input {
        height: 4.8rem;
        border-radius: 3rem;
        font-size: 1.3rem;
        color: $white;
        font-weight: 600;
        padding: 1rem 2rem;
        width: 100%;
        border: 0;
        background-color: rgba(255, 255, 255, .3);
        margin-bottom: 2.5rem;
        @media (max-width:991px) {
          margin-bottom: 2rem;
        }
        &:focus {
          outline: 0;
        }
        &::placeholder {
          color: rgba(255, 255, 255, .5);
        }
        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, .5);
        }
      }
    }
  }
  .modal-body {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &Inner {
    position: relative;
    width: 100%;
    border-radius: 14px;
    opacity: 1;
    backdrop-filter: blur(29px);
    padding: 3rem 2rem;
    @media (max-width:991px) {
      padding: 2rem 1rem;
    }
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background: $black;
      position: absolute;
      z-index: -1;
      opacity: .3;
      border-radius: 1.4rem;
    }
    .error-style {
      margin-top: -1rem;
    }
  }
  &.TicketConfirmModal {
    display: none;
    .modal-content {
      height: 588px;
      // background-image: url(https://d1ygcv609zmhy5.cloudfront.net/SidhuBg2.png);

      background-image: url(../../../assets/Images/ticket-bg.jpg);
      @media (max-width:1539px) {
        height: 550px;
      }
      @media (max-width:991px) {
        height: 450px;
      }
    }
  }
  .otpHandle {
    label {
      font-size: 1.2rem;
      display: block;
      text-align: left;
      margin-bottom: .5rem;
      font-weight: 600;
      color: $white;
    }
    .otp_input_root {
      display: flex;
      justify-content: space-between;
    }
    input {
      height: 4.8rem !important;
      width: 4.8rem !important;
      border-radius: .5rem;
      border: 0;
      padding: .5rem;
      font-size: 1.1rem;
      margin: 0 0 .5rem !important;
      background-color: transparent;
      font-weight: 600;
      color: $white;
      vertical-align: middle;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.3);
      &:focus {
        outline: none;
        text-align: center;
      }
    @media (max-width:479px) {
      height: 4rem !important;
      width: 4rem !important;
    }
    }
    &Resend {
      padding-bottom: 1rem;
      span {
        font-size: 1.2rem;
        font-weight: 600;
        color: $white;
      }
      button {
        background-color: transparent;
        font-size: 1.2rem;
        font-weight: 600;
        color: $white;
        border: 0;
      }
    }
  }
}