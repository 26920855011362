@import '../../../assets/_var.scss';
.footer {
    .form-label {
        padding-top: 1.7rem;
        padding-bottom: 1rem;
        font-size: 3rem;
        font-weight: bold;
        color: $yellowclr;
        @media (max-width:1679px) {
            font-size: 2.5rem;
        }
        @media (max-width:1439px) {
            font-size: 2.2rem;
        }
        @media (max-width:991px) {
            font-size: 2rem;
        }
    }
    &Info {
        p {
            color: $white;
            font-size: 1.4rem;
            margin-bottom: 0;
            margin-top: 2rem;
            span {
                color: $yellowclr;
            }
        }
    }
    &Imp {
        ul {
            li {
                a {
                    font-size: 1.4rem;
                    margin-bottom: 2rem;
                    color: $white;
                    text-decoration: none;
                    display: inline-block;
                    transition: .3s all ease-in-out;
                    &:hover {
                        color: $yellowclr;
                    }
                }
            }
        }
    }
    &Subs {
        a {
            display: inline-block;
            margin: 1rem 1.3rem 0 0;
        }
        .footerFrom {
            margin-bottom: 3rem;
            input {
                font-weight: normal;
                @media (max-width:991px) {
                    padding-right: 11.5rem;
                }
            }
        }
    }
    &CopyRight {
        margin-top: 4rem;
        border-top: 1px solid rgba(255, 255, 255, .2);
        font-size: 1.4rem;
        text-align: center;
        color: $white;
        padding: 5rem 0;
    }
}