@import "../../../assets/_var.scss";

.chart-img {
  background: $baseBg-4;
  border-radius: 1.4rem;
  padding: 3rem;

  img {
    width: 100%;
    height: 100%;
    max-height: 40.5rem;
  }
  @media (max-width:1679px) {
    padding: 2rem;    
  }
}
