@font-face {
  font-family: "stretch_proregular";
  src: url("./fonts/stretchpro-webfont.woff2") format("woff2"),
    url("./fonts/stretchpro-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

$baseFont: "Poppins", sans-serif;
$baseFont2: "stretch_proregular";
$baseBg: #ffffff;
$baseBg-2: #f8f8f5;
$baseBg-3: #1a1026;
$baseBg-4: #231731;
$baseBg-5:#4d3668;

$white: #ffffff;
$black: #000000;
$yellowclr: #fbd254;
$yellowClr2: #DBD86A;
$yellowclr-light: rgba(251, 210, 84, 0.05);
$borderClr: #777777;
$borderClr2: #2d2537;
$neon:#09e5f3;
$purple500:#a586cc;
$purplelight:#b9a0d7;


$pink: #e01ed7;

