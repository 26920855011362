@import "../../../../../assets/_var.scss";
.platform_sec {
  background: url("https://d1ygcv609zmhy5.cloudfront.net/body_bg.jpg") no-repeat;
  // background: url("../../../../../assets/Images/metabuild.png") no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .SidhuHimSelf,
  .HandsUp,
  .StayTuned {
    position: absolute;
  }
  .SidhuHimSelf {
    right: -10px;
    bottom: -130px;
    width: 100%;
    max-width: 1060px;
    z-index: 1;
    @media (max-width:1679px) {
      right: 80px;
      bottom: -130px;
      max-width: 860px;
    }
    @media (max-width:1439px) {
      right: 90px;
      bottom: -110px;
      max-width: 700px;
    }
    @media (max-width:1279px) {
      position: static;
    }
  }
 
  .HandsUp {
    width: 100%;
    max-width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
  }
  .StayTuned {
    right: 295px;
    bottom: -70px;
    width: 100%;
    max-width: 300px;
    z-index: 3;
    @media (max-width:1679px) {
      right: 345px;
      bottom: -70px;
      max-width: 220px;
    }
    @media (max-width:1439px) {
      right: 305px;
      bottom: -50px;
      max-width: 180px;
    }
  }
  .MobileHandle {
    .SidhuHimSelfMobile {
      @media (min-width:1280px) {
        display: none !important;
      }
    }
    @media (max-width:1279px) {
      margin: 0 auto;
      text-align: center;
      
      position: relative;
      .StayTuned {
         bottom: 50px;
        max-width: 180px;
        transform: translateX(-50%);
        left: 50%;
        right: inherit;
        margin-left: -140px;
      }
    img:not(.SidhuHimSelfMobile) {
      display: none;
    }
    }

  }
  &_inner {
    padding-left: 100px;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    >.container {
      position: relative;
    }
    .content_handle {
      position: relative;
      z-index: 5;
      @media (max-width:1279px) {
        text-align: center;
      }
    }
    .MooseText {
      margin-left: -60px;
      width: 100%;
      max-width: 650px;
      @media (min-width:1280px) {
        &.mob {
          display: none;
        }
      }
      @media (max-width:1679px) {
        margin-left: -42px;
        max-width: 500px;
      }
      @media (max-width:1439px) {
        margin-left: -42px;
        max-width: 450px;
      }
      @media (max-width:1279px) {
        max-width: 450px;
        margin-left: 0;
        &.desk {
          display: none;
        }
      }

    }
    h1 {
      margin-top: -1rem;
      margin-bottom: 3.5rem;
      // font-size: 3.8rem;
      font-size: 3.45rem;
      font-family: $baseFont2;
      color: $white;
      line-height: 1.8;
      text-shadow: 0 0 42px rgba(251, 210, 84, .8);
      sup {
        top: -1rem;
      }
      span {
        color: $yellowclr;
        display: block;
      }
      @media (max-width:1679px) {
        margin-top: -1rem;
        margin-bottom: 3rem;
        font-size: 2.7rem;
        sup { 
          top: -0.8rem;
          font-size: 1.8rem;
        }
      }
      @media (max-width:1439px) {
        font-size: 2.5rem;
        line-height: 1.5;
        sup { 
          top: -0.7rem;
font-size: 1.6rem;
        }
      }
    @media (max-width:767px) {
      font-size: 2rem;
      sup { 
        top: -0.4rem;
font-size: 1.4rem;
      }
      
    }
    }
    .btn-style {
      background-color: $black;
    }
    .bottom {
      display: block;
      // padding-top: 10rem;
      padding-top: 5rem;
      @media (max-width:1679px) {
        padding-top: 0px;
      }
    }
    .NeverDie {
      margin-top: 5rem;
      width: 100%;
      max-width: 770px;
      @media (max-width:1679px) {
        margin-top: 5rem;
        max-width: 620px;
      }
      @media (max-width:1439px) {
        margin-top: 4rem;
        max-width: 540px;
      }
    }
    .h1 {
      position: relative;
      z-index: 1;
      margin-top: -1.6rem;
      span {
        color: $yellowclr;
      }
      // font-size: 3.8rem;
      font-size: 4.1rem;
      font-family: $baseFont2;
      color: $white;
      letter-spacing: 5px;
      line-height: 1.8;
      text-shadow:0 0 42px rgba(251, 210, 84, .8);
      @media (max-width:1679px) {
        margin-top: -1rem;
        font-size: 3.3rem;
      }
      @media (max-width:1439px) {
        margin-top: -1rem;
        font-size: 2.8rem;
      }
      @media (max-width:767px) {
        font-size: 2rem;
        letter-spacing: 0;
        
      }
    }
  @media (max-width:1279px) {
    padding-top: 100px;
    height: auto;
    padding-left:0
  }
  }
  @media (max-width:1279px) {   
    &:not(.error) {

      height: auto;
      padding-bottom: 100px;
    }
   
  }
  &.error{
    .platform_sec_inner {
      padding-left: 0;
      @media (max-width:1279px) {  
      height: 100vh;
      }
    }
  }
}