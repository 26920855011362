@import "../../../../assets/_var.scss";

.dashboard-card {
  color: $white;
  border-radius: 1.4rem;
  padding: 3rem;
  background: $baseBg-4;
  display: flex;
  align-items: center;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.5rem;
    flex-shrink: 0;
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    background: $yellowclr-light;
    @media (max-width:991px) {
      display: none;
    }
  }

  &__content {
    flex: 1 0;
    h3 {
      font-size: 1.8rem;
    }
    h2 {
      color: $yellowclr;
      font-weight: 600;
    }
    @media (max-width:991px) {
     flex: 0 0 100%;
    }
  }
  @media (max-width:1679px) {
    padding: 2.2rem 2rem;
    &__icon { 
      margin-right: 2rem; 
      width: 5rem;
      height: 5rem; 
    }
  
    &__content {
      flex: 1 0;
      h3 {
        font-size: 1.6rem;
      }
      h2 {        
        font-size: 1.8rem;
      }
    }
  }
  @media (max-width:991px) {
    padding: 2rem;
    &__icon { 
      margin-right: 2rem; 
      width: 4rem;
      height: 4rem; 
    }
  
    &__content {
      flex: 1 0;
      h3 {
        font-size: 1.4rem;
      }
      h2 {        
        font-size: 1.6rem;
      }
    }
  }
}
