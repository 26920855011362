@import "../../../assets/_var.scss";
.siderbarStyle {
  &__logo {
    padding: 3rem 2rem;
    text-align: center;
    border-bottom: 1px solid $borderClr2;
    margin-bottom: 2rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-width: 100%;
    @media (max-width:1679px) {
      padding: 2.85rem 2rem;
    }
      @media (max-width:1439px) {
      padding: 2.25rem 2rem;
    }
    @media (max-width:767px) {
      padding: 2.08rem 2rem;
    }
  }
  &.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    border-right: 1px solid $borderClr2;
    z-index: 999;
    .sidebar-inner, .ps-sidebar-container {
      background: $baseBg-3;
      color: $yellowclr;
      font-size: 1.6rem;
      font-weight: 500; 
      display: flex;
flex-direction: column;
     
    }
    .menu, nav {
      // flex: 0 0 calc(100% - 12.7rem);
      // max-height: calc(100% - 12.7rem);
      flex: 0 0 100%;
      padding-top: 14rem;
      ul, > ul {
        display: flex;
flex-direction: column;
        height: 100%;
        >li {
          &:last-child {
            margin-top: auto;
            a {
              border-top: 1px solid $borderClr2;
          text-align: center;
          

            }

          }
          a {
            @media (max-width:1199px) {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    .menu, nav {
      ul, > ul  {
        > li{
          margin-bottom: 0.5rem;
          .menu-anchor, > a {
            &:hover,
            &.active {
              color: $black;
              background: $yellowclr;
            }
          }
          &.active {
            color: $black;
            background: $yellowclr;
            .menu-anchor, > a {
              background: $yellowclr;
            }
          }
        }
      }
    }
    
    &.collapsed, &.ps-collapsed {
      width: 250px;
      min-width: 250px;
      left: -250px;
    }
    @media (max-width:1439px) {
     .menu, nav {
      padding-top: 13rem;
      // flex: 0 0 calc(100% - 11.7rem);
      // max-height: calc(100% - 11.7rem);
     }
    }
      
        @media (max-width:1199px) {
      left: -250px;
      &.collapsed, &.ps-collapsed {
        left: 0;
      }
    }
    @media (max-width:767px) {
      .menu, nav { 
        padding-top: 12rem;
      }

    }
  }
}