@import '../../../../../assets/_var.scss';

.AboutFaq {
.real_faq{
    background-color: rgba(255, 255, 255, 0.16);
    position: relative;
    border-radius: 2rem;
    padding: 4.5rem 6rem;
    backdrop-filter: blur(16px); 
    margin: 7rem 0 8rem;
    @media (max-width:1679px) {
        padding: 3rem 4rem;
        margin: 2rem 0 5rem;
    }
    @media (max-width:991px) {
        padding: 3rem;
    }
    @media (max-width:767px) {
        padding: 0;
        background-color: transparent;
        backdrop-filter: unset;
        margin: 0rem 0 5rem;
    }
    h2.sec_title{
        color: $yellowclr;
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 3rem;
        @media (max-width:1679px) {
            margin-bottom: 1rem;
            font-size: 2.8rem;
        }
        @media (max-width:1439px) {
            font-size: 2.6rem;
        }
        @media (max-width:767px) {
            font-size: 2.2rem; text-align: center;
        }
    }
    .accordion {
        background-color: transparent;
        border: 0;

        &-item {
            margin-top: 3rem;
            padding: 3rem 6rem;
            background-color: rgba(14, 14, 14, .3);
            backdrop-filter : unset;
            @media (max-width:1679px) {
                padding: 2.5rem 4rem;
            }
            @media (max-width:1439px) {
                padding: 2.5rem;
            }
            @media (max-width:1439px) {
                padding: 2rem 2.5rem;
            }
            @media (max-width:767px) {
                background-color: rgba(255, 255, 255, .2);
                backdrop-filter: blur(10px); 
                padding: 2rem 2.5rem;
            }

        }

        &-button {
            border-radius: 0;
            padding: 0;
            padding-right: 4.7rem;
            font-size: 2.5rem;
            font-weight: 700;
            color: $yellowclr;
            background-color: transparent;
            position: relative;
            &:focus {
                outline: none;
                border: 0;
                box-shadow: none;
            }
            span {
                background: #fbd254;
                min-width: 50px;
                height: 50px;
                display: inline-block;
                color: #222;
                text-align: center;
                line-height: 50px;
                border-radius: 50px;
                margin-right: 20px;
                @media (max-width:1679px) {
                    min-width: 45px;
                    height: 45px;
                    line-height: 45px;
                }
                @media (max-width:991px) {
                    min-width: 35px;
                    height: 35px;
                    line-height: 35px;
                    margin-right: 15px;
                }
                @media (max-width:991px) {
                    min-width: 25px;
                    height: 25px;
                    line-height: 25px;
                    margin-right: 10px;
                }
            }
            
            @media (max-width:1679px) {
                font-size: 2rem;
                &:after {
                    height: 30px;
                    width: 30px;
                }
            }
            @media (max-width:1439px) {
                font-size: 1.8rem;
                &:after {
                    height: 25px;
                    width: 25px;
                }
            }
            @media (max-width:1199px) {
                font-size: 1.6rem;
                &:after {
                    height: 20px;
                    width: 20px;
                }
            }
            @media (max-width:767px) {
                font-size: 1.5rem;
            }

            &:before {
                content: "";
                height: calc(100% + 9rem);
                width: calc(100% + 12rem);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 2rem;

                @media (max-width:1679px) {
                    height: calc(100% + 6.5rem);
                    width: calc(100% + 8rem);
                }
                @media (max-width:1439px) {
                    height: calc(100% + 6rem);
width: calc(100% + 6rem);
                }
            }
        }

        &-body {
            border-top: 1px solid rgba(255, 255, 255, .1);
            padding: 0;
            padding-top: 1rem;
            margin-top: 3rem;

            p {
                padding-top: 1.5rem;
                font-size: 1.4rem;
                color: $white;
                line-height: 2;

                span,
                strong {
                    color: $yellowclr;
                }
            strong {
                margin-right: 15px;
            }
            }
        }
    }
}
}