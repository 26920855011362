@import '../../../assets/_var.scss';
.loginbox {
    max-width: 700px;
    border-radius: 1rem;
    margin: 0 auto;
    padding: 4rem 3rem;
    background-color: rgba(26, 16, 38, .5);
    h1 {
        font-size: 2rem;
        line-height: 1.5;
    }
    label {
        text-align: left;
    }
    .inner {
        background-color: rgba(26, 16, 38, .7);
        padding: 5rem;
        border-radius: 1rem;
    }
    @media (max-width:1679px) {
        h1 {
            font-size: 1.8rem;
        }
    }
    @media (max-width:1199px) {
        h1 {
            font-size: 1.6rem;
        }
        padding: 3rem 2rem;
        .inner {
            padding: 3rem 2rem;
        }
    }
}