@import "../../../assets/_var.scss";

.price-management {
  padding: 3rem 0;
  .dashboard-card {
    border-bottom: 0.1rem solid $borderClr2;
    border-radius: 0;

    &:last-of-type {
      border-bottom: 0;
    }
  }
  @media (max-width:1199px) {
    padding: 1rem 0;
  }
}
