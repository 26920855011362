@import "../../../assets/_var.scss";

.overlayloader {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 100000;
  height: 100%;
  background-color: rgba(42, 46, 47, 0.8);
}

.loader-style {
  &.spinner-border {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 2rem;
  }
}
