@import "../../../assets/_var.scss";
.btn-style {
  border: 1px solid $yellowclr;
  background-color: $yellowclr;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
  font-weight: 600;
  font-size: 1.6rem;
  min-width: 14rem;
  background: $yellowclr;
  color: $white;
  padding: 1.05rem 2.8rem;
  position: relative;
  transition: all 0.3s ease-in-out;
  color: $black;
  @media (max-width: 991px) {
    font-size: 1.4rem;
    min-width: 10rem;
    padding: 0.8rem 2.2rem;
  }
  // &.white-btn {
  //   background: $white;
  //   color: $black;
  //   box-shadow: inset 0px 0px 6px #0000009E, 0px 0px 6px #FFFFFF57
  // }
  &.BorderBtn {
    background-color: transparent;
    color: $yellowclr;
    
  }
  &.headerBtn {
    background-color: transparent;
    font-weight: 600;
    font-size: 2.023rem;
    box-shadow: inset 0 -5px 14px $yellowclr;
    border: 4px solid $yellowclr;
    min-width: 20.2rem;
    transform: .3s all ease-in-out;
    color: $yellowclr;
    &:before {
      transition: .3s all ease-in-out;
      content: "";
      height: 100%;
      width: 100%;
      border-radius: 3rem;
      box-shadow: 0 0 14px $yellowclr;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    &:hover {
      box-shadow: inset 0 5px 14px $yellowclr;
    }
    @media (max-width:1679px) {
      font-size: 1.8rem;
    }
    @media (max-width:1439px) {
      font-size: 1.4rem;
      min-width: inherit;
      padding: .8rem 2rem;
    }
  }
svg {
  margin-left: 1rem;
}
&:not(.BorderBtn) {
  svg {
    path {
      fill:  $black;
    }
  }
}
}