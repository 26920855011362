@import "../../../assets/_var.scss";

.connect_wallet {
  .modal-dialog {
    // display: flex;
    // flex: 1;
    // height: auto;

    .modal-body {
      // display: flex;
      // flex-direction: column;
      padding: 1rem 2rem 2.5rem;
    }

    .modal-content {
      // background: $black;
      // color: $white;
      // border-radius: 1rem;
      // box-shadow: 0 0 24px $pink;
      // border: 4px solid $pink;
      // border-radius: 1.5rem;
      .modal-header {
        // border-bottom: 0;
        // padding: 2rem 2rem 1.5rem;
        // border-bottom: 1px solid $borderClr;
        // .modal-title {
        //   font-size: 2rem;
        //   font-weight: 700;
        // }
        // .btn-close {
        //   background: url(../../../assets/Images/close.svg) center no-repeat;
        //   outline: none;
        //   position: absolute;
        //   z-index: 9;
        //   right: 2.5rem;
        //   opacity: 1;
        //   filter: brightness(0) invert(1);
        //   &:focus {
        //     box-shadow: unset;
        //     outline: unset;
        //   }
        // }
      }
      .connect_options {
        ul {
          li {
            display: block;
            margin: 2rem 0;
            &:last-child {
              margin-bottom: 0;
            }
            .btn {
              color: $white;
              display: block;
              background: $purple500;
              position: relative;
              border: 0;
              text-align: left;
              font-size: 1.6rem;
              font-weight: 700;
              width: 100%;
              padding: 2rem;
              border-radius: 1.4rem;

              // border: 2px solid rgba(9, 229, 243, .1);
              &:hover {
                background: $yellowclr;
                // border-color: $yellowclr;
              }

              span {
                background-color: $white;
                display: block;
                width: 4rem;
                height: 4rem;
                display: flex;
                position: absolute;
                right: 2rem;
                top: 1.1rem;
                border-radius: 0.6rem;
                justify-content: center;
                align-items: center;
                box-shadow: 0 0 1.4rem rgba(0,0,0,.1);

                img {
                  width: 2.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
