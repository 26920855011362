@import '../../../assets/_var.scss';
.TimeMng {
    background-color: $baseBg-4;
    border-radius: 1rem;
    padding: 4rem 3rem;
    &box {
        h2 {
            color: $white;
            font-weight: 600;
            font-size: 2rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $borderClr2;
            @media (max-width:991px) {
                font-size: 1.8rem;
            }
        }
        label {
            color: $white;
            font-size: 1.6rem;
            display: block;
            font-weight: 600;
            margin-top: 2rem;
            @media (max-width:991px) {
                font-size: 1.4rem;
            }
        }
        .react-datetime-picker__wrapper {
            border: 0;
        }
        .react-date-picker, .react-datetime-picker {
            width: 100%;
            max-width: 100%;
            margin-top: 1rem;
        }
        .react-date-picker__wrapper, .react-datetime-picker {
            height: 4.8rem;
            border-radius: 3rem;
            font-size: 1.3rem;
            color: $white;
            font-weight: 600;
            padding: 1rem 2rem;
            width: 100%;
            border: 0;
            background-color: rgba(255, 255, 255, 0.3);
            margin-bottom: 2.5rem;
            input,
            span, select {
                color: $white;
                font-size: 1.4rem;
                &:focus {
                    outline: none;
                }
                @media (max-width:359px) {
                    font-size: 1rem;
                }
            }
            .react-date-picker__clear-button,
            .react-date-picker__calendar-button, .react-datetime-picker__button {
                svg {
                    opacity: .5;
                    width: 1.6rem;
                    line,
                    rect {
                        stroke: $yellowclr;
                    }
                    @media (max-width:359px) { 
                    width: 1.4rem;

                    }
                }
                &:hover {
                    svg {
                        opacity: 1;
                    }
                }
            }
        &.remove-date {
            input[class*="__month"], input[class*="__month"] + span, input[class*="__day"], input[class*="__day"] + span,  input[class*="__year"], input[class*="__year"] + span, .react-datetime-picker__calendar-button {
                display: none;
            }

        }
        &.remove-time {
            input[class*="__year"] + span, input[class*="__hour"], input[class*="__hour"] + span, input[class*="__minute"], input[class*="__minute"] + span,  select[class*="__amPm"]{
                display: none;
            }

        }
    .react-calendar__navigation {
        button {
            span {
                color: $black;
            }
        }
    }    
    }
    }
    @media (max-width:991px) { 
        padding: 3rem 2rem;
    }
}